<template>
  <v-row justify="center">
    <v-dialog v-model="packageVersion" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">LatLongo</v-card-title>
        <v-card-text>
          <div>
            <b style="color: black;">{{ $t("Installed Package") + ":" }}</b>
          </div>
          <div v-if="!errorMessage">{{ packageVersion }}</div>
          <div v-else style="color: red;">
            {{ errorMessage }}
          </div>
          <br />
          <div class="text-align-end">
            {{ appInfo }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAbout">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {api, basePath} from "@/api/index";

export default {
  name: "about",
  data() {
    return {
      packageVersion: this.$t("Fetching installed package version") + "...",
      errorMessage: "",
      appInfo:
        this.$t(
          "LatLonGO provides the end-user with a system for distributing "
        ) +
        this.$t(
          "Enterprise Resource Planning (ERP) information seamlessly to mobile "
        ) +
        this.$t(
          "offline sometimes connected devices (Android, iOS, Windows native "
        ) +
        this.$t(
          "tablet and phone UI) as well as web clients. It comes bundled with "
        ) +
        this.$t("HERE Maps.")
    };
  },
  created() {
    console.log("...'About' window created.");
    api.get(basePath + "services/version").then(
      response => {
        this.packageVersion = response.data;
      },
      error => {
        this.errorMessage = this.$t(
          "Error in fetching the installed package version."
        );
      }
    );
  },

  methods: {
    closeAbout() {
      this.$store.commit("changeIsAboutDialogState", false);
    }
  }
};
</script>
<style>
.text-align-end {
  text-align: justify;
}
</style>
